<template>
  <div class="app-container" style="margin-top:50px;width:100%">
    <div class="blod">盈亏统计</div>
    <eHeader :query="query" />
    <!--表格渲染-->
    <div style="margin-top:80px; ">
      <el-row >
        <el-col :span="6">订单总盈亏($)</el-col>
        <el-col :span="6"> {{data.orderProfit}}</el-col>
        <el-col :span="6">订单总手续费($)</el-col>
        <el-col :span="6">{{data.orderCost}}</el-col>
      </el-row>
      <el-row >
        <el-col :span="6">订单总利息($)</el-col>
        <el-col :span="6"> {{data.orderInterest}}</el-col>
        <el-col :span="6">订单总手数</el-col>
        <el-col :span="6">{{data.orderVolume}}</el-col>
      </el-row>
      <el-row >
        <el-col :span="6">订单总内佣($)</el-col>
        <el-col :span="6"> {{data.orderInternal}}</el-col>
        <el-col :span="6">订单总外佣($)</el-col>
        <el-col :span="6">{{data.orderForeign}}</el-col>
      </el-row>
      <el-row >
        <el-col :span="6">交易总盈亏($)</el-col>
        <el-col :span="6"> {{data.transactionProfit}}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { initData } from '@/api/data'
import { parseTime,parseDate } from '@/utils/index'
import eHeader from '@/components/stat/report/accPlTotal/header'
export default {
  components: { eHeader},
  props:{
  },
  data() {
    return {
      sup_this: this,
      data: {},
      url: '',
      params: {},
      query: {}
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseDate,
    parseTime,
    beforeInit() {
      this.url = '/crm/queryStatistics'
      this.params = { queryType:3}
      const query = this.query
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      return true
    },
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      return new Promise((resolve, reject) => {
        initData(this.url, this.params).then(res => {
          console.log('this.data.orderProfit')
          this.data = res[0]
          console.log(this.data.orderProfit)
        }).catch(err => {
        })
      })
    },
  }
}
</script>

<style scoped>
  .blod {
    font-weight: 600;
    font-size: 22px;
  }
  .el-col {
    height: 50px;
    border: 1px solid ;
    text-align: center;
    line-height: 50px;
    margin:0px -1px -1px 0px;
  }
</style>
